<template>
  <div class="fbmp-page">
    <FbmpHeader />
    <FbmpContent :redirectToSuccess="redirectToSuccess"/>
    <!-- <button @click="redirectToSuccess">Test Success Page</button> test success page button -->
  </div>
</template>

<script>
import FbmpHeader from './FbmpHeader.vue';
import FbmpContent from './FbmpContent.vue';

export default {
  name: 'FbmpPage',
  components: {
    FbmpHeader,
    FbmpContent
  },
  props: {
    redirectToSuccess: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped>
.fbmp-page {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-x: hidden; 
}

@media (max-width: 991px) {
  .fbmp-page {
    max-width: 100%;
  }
}
</style>
