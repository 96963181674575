<template>
  <div id="app">
    <component :is="currentPage" :redirectToSuccess="redirectToSuccess" />
  </div>
</template>

<script>
import FbmpPage from './components/FbmpPage.vue';
import FbmpSuccessPage from './components/FbmpSuccessPage.vue';

export default {
  name: 'App',
  components: {
    FbmpPage,
    FbmpSuccessPage
  },
  data() {
    return {
      currentPage: 'FbmpPage'
    }
  },
  methods: {
    redirectToSuccess() {
      this.currentPage = 'FbmpSuccessPage';
    }
  }
}
</script>

<style>
@import './assets/styles.css';
</style>